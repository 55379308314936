import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import "./NavDropdown.css";

export const NavDropdown = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleRef = useRef<any>(null);

  const handleDropdownMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (event: any, page: string) => {
    event.preventDefault();
    history?.push(page);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        toggleRef.current &&
        !toggleRef.current.contains(event.target) &&
        !event.target.pathname
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="smgm-dropdown-btn">
        <i
          ref={toggleRef}
          className="fa-solid fa-bars"
          onClick={handleDropdownMenuClick}
        ></i>
      </div>
      <div
        className="smgm-dropdown-menu open"
        style={isOpen ? { display: "block" } : { display: "none" }}
      >
        <li className="smgm-menu-container">
          <a
            className="smgm-menu"
            href="#"
            onClick={(event) => handleMenuClick(event, "home")}
          >
            Home
          </a>
        </li>
        <li className="smgm-menu-container">
          <a className="smgm-menu" href="#">
            About Us
          </a>
          <div className="smgm-submenu-container">
            <a href="#" onClick={(event) => handleMenuClick(event, "about-us")}>
              About Us
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "vision-and-mission")}
            >
              Vision and Mission
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "board-of-directors")}
            >
              Board of Directors
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "photo-album")}
            >
              Photo Album
            </a>
          </div>
        </li>
        <li className="smgm-menu-container">
          <a className="smgm-menu" href="#">
            Investor
          </a>
          <div className="smgm-submenu-container">
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "annual-report")}
            >
              Annual Report
            </a>
            <a href="#" onClick={(event) => handleMenuClick(event, "dividend")}>
              Dividend
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "share-capital")}
            >
              Share Capital
            </a>
          </div>
        </li>
        <li className="smgm-menu-container">
          <a className="smgm-menu" href="#">
            Deposit
          </a>
          <div className="smgm-submenu-container">
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "daily-saving")}
            >
              Daily Saving
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "monthly-saving")}
            >
              Monthly Saving
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "super-saving")}
            >
              Super Saving
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "children-saving")}
            >
              Children Saving
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "sr-citizen-saving")}
            >
              Sr. Citizen Saving
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "share-bridhi-saving")}
            >
              Share Bridhi Saving
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "khutruke-saving")}
            >
              Khutruke Saving
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "avadhik-saving")}
            >
              Avadhik Saving
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "fixed-deposits")}
            >
              Fixed Deposits
            </a>
          </div>
        </li>
        <li className="smgm-menu-container">
          <a className="smgm-menu" href="#">
            Loan
          </a>
          <div className="smgm-submenu-container">
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "personal-loan")}
            >
              Personal Loan
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "business-loan")}
            >
              Business Loan
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "house-loan")}
            >
              House Loan
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "hire-purchase-loan")}
            >
              Hire Purchase Loan
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "education-loan")}
            >
              Education Loan
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "term-loan")}
            >
              Term Loan
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "fd-receipt-loan")}
            >
              FD Receipt Loan
            </a>
          </div>
        </li>
        <li className="smgm-menu-container">
          <a className="smgm-menu" href="#">
            Service
          </a>
          <div className="smgm-submenu-container">
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "mobile-banking")}
            >
              Mobile Banking
            </a>
            <a
              href="#"
              onClick={(event) =>
                handleMenuClick(event, "home-cash-collection")
              }
            >
              Home Cash Collection
            </a>
            <a
              href="#"
              onClick={(event) => handleMenuClick(event, "transaction-hour")}
            >
              Transaction Hour
            </a>
          </div>
        </li>
        <li className="smgm-menu-container">
          <a className="smgm-menu" href="#">
            Contact Us
          </a>
          <div className="smgm-submenu-container">
            <a href="#" onClick={(event) => handleMenuClick(event, "contact")}>
              Contact
            </a>
          </div>
        </li>
        <li className="smgm-menu-container">
          <a className="smgm-menu" href={process.env.REACT_APP_GROCERY_URL}>
            Grocery
          </a>
        </li>
        <li className="smgm-menu-container">
          <a
            className="smgm-menu"
            href="#"
            onClick={(event) => handleMenuClick(event, "login")}
          >
            Login
          </a>
        </li>
      </div>
    </>
  );
};
