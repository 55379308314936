const storageKey = "smgm-token";

export const resizeImage = (file: any, maxWidth: number, maxHeight: number) => {
  return new Promise<File>((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height; // Calculate new dimensions

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(new File([blob], file.name, { type: file.type }));
          }
        }, file.type);
      }
    };

    img.onerror = reject;
  });
};

export const renameFile = (file: any, newFileName: string) => {
  return new File([file], newFileName, { type: file.type });
};

export const isStorageKeyAvailable = (): boolean => {
  const smgmToken = window.sessionStorage.getItem(storageKey);
  if (smgmToken) {
    return true;
  } else {
    return false;
  }
};

export const getStorageKey = (): string => {
  return window.sessionStorage.getItem(storageKey) || "";
};

export const setStorageKey = (token: string) => {
  window.sessionStorage.setItem(storageKey, token);
};

export const removeStorageKey = () => {
  window.sessionStorage.removeItem(storageKey);
};
