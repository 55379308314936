import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import { Header } from "../../../_components/header";
import { FileUpload } from "../../../_components/file-upload";
import { getStorageKey } from "../../../services/utility.service";
import { PageSettingsModal } from "../../../models/PageSettings.model";
import { renameFile } from "../../../services/utility.service";
import { MessageModal } from "../../../_components/modals/message-modal";
import "./PageSettings.css";

export const PageSettings = () => {
  const defaultPageName: string = "Page";
  const history = useHistory();
  const apiUrl = process.env.REACT_APP_API_URL || "";

  const [pageName, setPageName] = useState<string>(defaultPageName);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);

  const [firstParagraphText, setFirstParagraphText] = useState<string>("");
  const [secondParagraphText, setSecondParagraphText] = useState<string>("");
  const [thirdParagraphText, setThirdParagraphText] = useState<string>("");
  const [signatureText, setSignatureText] = useState<string>("");
  const [pageImagePath, setPageImagePath] = useState<string>("");
  const [noticeImagePath, setNoticeImagePath] = useState<string>("");

  const [pageImage, setPageImage] = useState<any>({});
  const [noticeImage, setNoticeImage] = useState<any>({});
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isCleared, setIsCleared] = useState<boolean>(false);

  const clearSettings = () => {
    setPageName(defaultPageName);
    setFirstParagraphText("");
    setSecondParagraphText("");
    setThirdParagraphText("");
    setSignatureText("");

    setIsCleared(true);
  };

  const getPageSettings = (pageName: string) => {
    let config = {};
    const smgmToken = getStorageKey();
    if (smgmToken) {
      config = {
        headers: {
          Authorization: smgmToken,
        },
      };
    } else {
      history.push("/login");
    }

    try {
      if (pageName) {
        const updatedPageName = pageName.toLowerCase().replace(/\s/g, "");
        axios
          .get(`${apiUrl}/website-page-settings/${updatedPageName}`, config)
          .then((response: any) => {
            if (response.data.length > 0) {
              setFirstParagraphText(response.data[0].FirstParagraphText);
              setSecondParagraphText(response.data[0].SecondParagraphText);
              setThirdParagraphText(response.data[0].ThirdParagraphText);
              setSignatureText(response.data[0].SignatureText);
              setPageImagePath(response.data[0].PageImagePath);
              setNoticeImagePath(response.data[0].NoticeImagePath);
            }
          });
      }
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  };

  const updatePageSettings = async (pageName: string) => {
    let config = {};
    const smgmToken = getStorageKey();
    if (smgmToken) {
      config = {
        headers: {
          Authorization: smgmToken,
        },
      };
    } else {
      history.push("/login");
    }

    let newPageImageName = "";
    let newNoticeImageName = "";

    const formData = new FormData();

    if (pageImage && pageImage?.name) {
      let originalPageImageName = pageImage.name;
      newPageImageName =
        pageName.toLowerCase().replace(/\s/g, "") +
        "-page-image." +
        originalPageImageName.split(".").pop();
      const newPageImage = renameFile(pageImage, newPageImageName);
      //const resizedImage = await resizeImage(newPageImage, 200, 250);
      formData.append("images", newPageImage);
    } else if (pageImagePath) {
      newPageImageName = pageImagePath;
      formData.append("images", pageImagePath);
    }

    if (noticeImage && noticeImage?.name) {
      let originalNoticeImageName = noticeImage.name;
      newNoticeImageName =
        pageName.toLowerCase().replace(/\s/g, "") +
        "-notice-image." +
        originalNoticeImageName.split(".").pop();
      const newNoticeImage = renameFile(noticeImage, newNoticeImageName);
      //const resizedImage = await resizeImage(newNoticeImage, 200, 250);
      formData.append("images", newNoticeImage);
    } else if (noticeImagePath) {
      newNoticeImageName = noticeImagePath;
      formData.append("images", noticeImagePath);
    }

    const pageSettings: PageSettingsModal = {
      pageName: pageName.toLowerCase().replace(/\s/g, ""),
      firstParagraphText: firstParagraphText,
      secondParagraphText: secondParagraphText,
      thirdParagraphText: thirdParagraphText,
      signatureText: signatureText,
      pageImagePath: newPageImageName,
      noticeImagePath: newNoticeImageName,
      updatedBy: "admin",
      updatedDate: new Date().toISOString().slice(0, 19).replace("T", " "),
    };
    try {
      axios
        .put(`${apiUrl}/website-page-settings`, pageSettings, config)
        .then((response) => {
          if (formData.append.length > 0) {
            try {
              axios
                .post(
                  `${apiUrl}/website-page-settings/upload-images`,
                  formData,
                  config
                )
                .then((response) => {
                  setIsDisabled(true);
                  setMessage(`Settings updated successfully for ${pageName}`);
                  setShowMessageModal(true);
                })
                .catch((error) => console.log(error));
            } catch (error) {
              // Handle error
            }
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  const deletePageSettings = (pageName: string) => {
    let config = {};
    const smgmToken = getStorageKey();
    if (smgmToken) {
      config = {
        headers: {
          Authorization: smgmToken,
        },
      };
    } else {
      history.push("/login");
    }

    try {
      axios
        .delete(`${apiUrl}/website-page-settings/${pageName}`, config)
        .then((response) => {
          clearSettings();
          setMessage(`Settings deleted successfully for ${pageName}`);
          setShowMessageModal(true);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (e: any) => {
    setPageName(e);
  };

  const handleClick = (action: string) => {
    if (action == "show") {
      getPageSettings(pageName);
    } else if (action == "edit") {
      setIsDisabled(false);
    } else if (action == "update") {
      updatePageSettings(pageName);
    } else if (action == "clear") {
      clearSettings();
    } else if (action == "delete") {
      deletePageSettings(pageName);
      clearSettings();
    }
  };

  const handlePageImageChange = (pageImage: any) => {
    setPageImage(pageImage);
    if (!pageImage) {
      setPageImagePath("");
    }
  };

  const handleNoticeImageChange = (noticeImage: any) => {
    setNoticeImage(noticeImage);
    if (!noticeImage) {
      setNoticeImagePath("");
    }
  };

  const handleMessageModalClose = () => {
    setShowMessageModal(false);
  };

  return (
    <>
      <Header />
      <div className="smgm-container">
        <div className="smgm-pagesettings-title">
          <h2>Page Settings</h2>
        </div>
        <div className="container smgm-pagesettings-content">
          <div className="row">
            <div
              className="col-lg-2 col-md-2"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <DropdownButton
                  title={pageName}
                  id="smgm-pagesettings-dropdown-menu"
                  onSelect={handleSelect}
                >
                  <div style={{ height: "200px", overflowY: "scroll" }}>
                    <Dropdown.Item eventKey="Home">Home</Dropdown.Item>

                    <Dropdown.Item eventKey="About Us">About Us</Dropdown.Item>
                    <div className="smgm-pagesettings-dropdown-submenu">
                      <Dropdown.Item eventKey="Vision and Mission">
                        Vision and Mission
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Board of Directors">
                        Board of Directors
                      </Dropdown.Item>
                    </div>

                    <Dropdown.Item eventKey="Investor">Investor</Dropdown.Item>
                    <div className="smgm-pagesettings-dropdown-submenu">
                      <Dropdown.Item eventKey="Annual Report">
                        Annual Report
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Dividend">
                        Dividend
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Share Capital">
                        Share Capital
                      </Dropdown.Item>
                    </div>

                    <Dropdown.Item eventKey="Deposit">Deposit</Dropdown.Item>
                    <div className="smgm-pagesettings-dropdown-submenu">
                      <Dropdown.Item eventKey="General Saving">
                        General Saving
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Daily Saving">
                        Daily Saving
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Monthly Saving">
                        Monthly Saving
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Super Saving">
                        Super Saving
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Children Saving">
                        Children Saving
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Sr. Citizen Saving">
                        Sr. Citizen Saving
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Share Bridhi Saving">
                        Share Bridhi Saving
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Khutruke Saving">
                        Khutruke Saving
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Avadhik Saving">
                        Avadhik Saving
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Fixed Deposits">
                        Fixed Deposits
                      </Dropdown.Item>
                    </div>

                    <Dropdown.Item eventKey="Loan">Loan</Dropdown.Item>
                    <div className="smgm-pagesettings-dropdown-submenu">
                      <Dropdown.Item eventKey=">Personal Loan">
                        Personal Loan
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Business Loan">
                        Business Loan
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="House Loan">
                        House Loan
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Hire Purchase Loan">
                        Hire Purchase Loan
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Education Loan">
                        Education Loan
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Term Loan">
                        Term Loan
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="FD Receipt Loan">
                        FD Receipt Loan
                      </Dropdown.Item>
                    </div>

                    <Dropdown.Item eventKey="Service">Service</Dropdown.Item>
                    <div className="smgm-pagesettings-dropdown-submenu">
                      <Dropdown.Item eventKey=">Mobile Banking">
                        Mobile Banking
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Home Cash Collection">
                        Home Cash Collection
                      </Dropdown.Item>
                    </div>

                    <Dropdown.Item eventKey="Quick Link">
                      Quick Link
                    </Dropdown.Item>
                    <div className="smgm-pagesettings-dropdown-submenu">
                      <Dropdown.Item eventKey=">Latest News">
                        Latest News
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Photo Album">
                        Photo Album
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Transaction Hour">
                        Transaction Hour
                      </Dropdown.Item>
                    </div>

                    <Dropdown.Item eventKey="Contact">Contact</Dropdown.Item>
                  </div>
                </DropdownButton>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  className="smgm-pagesettings-action-buttons"
                  variant="primary"
                  onClick={() => handleClick("show")}
                  disabled={pageName === defaultPageName}
                >
                  Show
                </Button>
                <Button
                  className="smgm-pagesettings-action-buttons"
                  variant="primary"
                  onClick={() => handleClick("edit")}
                  disabled={pageName === defaultPageName}
                >
                  Edit
                </Button>
                <Button
                  className="smgm-pagesettings-action-buttons"
                  variant="primary"
                  onClick={() => handleClick("update")}
                  disabled={pageName === defaultPageName}
                >
                  Update
                </Button>
                <Button
                  className="smgm-pagesettings-action-buttons"
                  variant="primary"
                  onClick={() => handleClick("clear")}
                  disabled={pageName === defaultPageName}
                >
                  Clear
                </Button>
                <Button
                  className="smgm-pagesettings-action-buttons"
                  variant="danger"
                  onClick={() => handleClick("delete")}
                  disabled={pageName === defaultPageName}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
          <div className="row smgm-pagesettings-form-row">
            <div className="col-lg-10 col-md-10">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="smgm-pagesettings-form-label">
                  First Paragraph
                </Form.Label>
                <Form.Control
                  as="textarea"
                  value={firstParagraphText}
                  onChange={(e) => setFirstParagraphText(e.target.value)}
                  rows={6}
                  disabled={isDisabled}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Label className="smgm-pagesettings-form-label">
                Signature
              </Form.Label>
              <Form.Control
                as="textarea"
                style={{ width: "160px" }}
                value={signatureText}
                onChange={(e) => setSignatureText(e.target.value)}
                rows={6}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="row smgm-pagesettings-form-row">
            <div className="col-lg-10 col-md-10">
              <Form.Group className="mb-3" controlId="secondParagraph">
                <Form.Label className="smgm-pagesettings-form-label">
                  Second Paragraph
                </Form.Label>
                <Form.Control
                  as="textarea"
                  value={secondParagraphText}
                  onChange={(e) => setSecondParagraphText(e.target.value)}
                  rows={6}
                  disabled={isDisabled}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Label className="smgm-pagesettings-form-label">
                Page Image
              </Form.Label>
              <div style={{ display: "flex", height: "160px", width: "180px" }}>
                <FileUpload
                  file={
                    pageImagePath
                      ? process.env.REACT_APP_API_STATIC_URL +
                        "/website/pages/" +
                        pageImagePath
                      : null
                  }
                  disabled={isDisabled}
                  clear={isCleared}
                  onChange={handlePageImageChange}
                />
              </div>
            </div>
          </div>
          <div className="row smgm-pagesettings-form-row">
            <div className="col-lg-10 col-md-10">
              <Form.Group className="mb-3" controlId="thirdParagraph">
                <Form.Label className="smgm-pagesettings-form-label">
                  Third Paragraph
                </Form.Label>
                <Form.Control
                  as="textarea"
                  value={thirdParagraphText}
                  onChange={(e) => setThirdParagraphText(e.target.value)}
                  rows={6}
                  disabled={isDisabled}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Label className="smgm-pagesettings-form-label">
                Notice Image
              </Form.Label>
              <div style={{ display: "flex", height: "160px", width: "180px" }}>
                <FileUpload
                  file={
                    noticeImagePath
                      ? process.env.REACT_APP_API_STATIC_URL +
                        "/website/pages/" +
                        noticeImagePath
                      : null
                  }
                  disabled={isDisabled}
                  clear={isCleared}
                  onChange={handleNoticeImageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showMessageModal && (
        <MessageModal message={message} onClose={handleMessageModalClose} />
      )}
    </>
  );
};
