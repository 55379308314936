export class PageSettingsModal {
  pageName: string | undefined;
  firstParagraphText: string | undefined;
  secondParagraphText: string | undefined;
  thirdParagraphText: string | undefined;
  signatureText: string | undefined;
  pageImagePath: string | undefined;
  noticeImagePath: string | undefined;
  updatedBy: string | undefined;
  updatedDate: string | undefined;
}
