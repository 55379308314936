import AppRouter from "./AppRouter";
import { CompanySettingsContextProvider } from "./context/CompanySettingsContext";
import { PageSettingsContextProvider } from "./context/PageSettingsContext";
import "./App.css";

function App() {
  return (
    <>
      <div className="App">
        <CompanySettingsContextProvider>
          <PageSettingsContextProvider>
            <AppRouter />
          </PageSettingsContextProvider>
        </CompanySettingsContextProvider>
      </div>
    </>
  );
}

export default App;
