import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Header } from "../../_components/header";
import { LoginModal } from "../../_components/modals/login-modal";
import {
  isStorageKeyAvailable,
  setStorageKey,
} from "../../services/utility.service";
import "./Login.css";

export const Login = () => {
  const history = useHistory();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const url = `${process.env.REACT_APP_API_URL}/website-user/login`;

  useEffect(() => {
    const smgmToken = isStorageKeyAvailable();
    if (smgmToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogin = (username: string, password: string) => {
    const data: any = { username: username, password: password };
    try {
      axios
        .post(`${url}`, data)
        .then((response: any) => {
          const token = response?.data?.token || "";
          setStorageKey(token);
          setIsError(false);
          history.push("/");
        })
        .catch((error) => {
          console.log(error);
          setIsError(true);
        });
    } catch (error) {
      // Handle error
    } finally {
    }
  };

  return (
    <>
      <Header isLoggedIn={isLoggedIn} />
      {!isLoggedIn && <LoginModal onLogin={handleLogin} isError={isError} />}
    </>
  );
};
