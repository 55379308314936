import React, { useState } from "react";
import { PageSettingsModal } from "../models/PageSettings.model";

export interface IPageSettingsContext {
  contextPageSettings: PageSettingsModal;
  updateContextPageSettings: (pageSettings: PageSettingsModal) => void;
}

export const PageSettingsContext = React.createContext<IPageSettingsContext>({
  contextPageSettings: new PageSettingsModal(),
  updateContextPageSettings: (pageSettings: PageSettingsModal) =>
    console.log(pageSettings),
});

export const PageSettingsContextProvider = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [contextPageSettings, setContextPageSettings] = useState<
    PageSettingsModal
  >(new PageSettingsModal());

  const updateContextPageSettings = (pageSettings: PageSettingsModal) => {
    setContextPageSettings(pageSettings);
  };

  return (
    <PageSettingsContext.Provider
      value={{
        contextPageSettings: contextPageSettings,
        updateContextPageSettings: updateContextPageSettings,
      }}
    >
      {props.children}
    </PageSettingsContext.Provider>
  );
};
