import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./NotificationModal.css";

export interface INotficationModalProps {
  imagePath: string;
  onClose: () => void;
}

export const NotificationModal = (props: INotficationModalProps) => {
  const [imagePath, setImagePath] = useState<string>(props.imagePath);

  useEffect(() => {
    setImagePath(props.imagePath);
  }, [props.imagePath]);

  const handleHide = () => {
    props.onClose();
  };

  return (
    <>
      <Modal show={true} onHide={handleHide} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ height: "560px" }}>
          <img
            src={imagePath}
            style={{ height: "100%", width: "100%" }}
            alt=""
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
