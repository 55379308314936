export class CompanySettingsModal {
  companyName: string | undefined;
  companyTitle: string | undefined;
  companyAddress: string | undefined;
  companyContactNo: string | undefined;
  companyLogoPath: string | undefined;
  companyBanner1Path: string | undefined;
  companyBanner2Path: string | undefined;
  companyBanner3Path: string | undefined;
  companyBanner4Path: string | undefined;
  updatedBy: string | undefined;
  updatedDate: string | undefined;
}
