import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { Header } from "../../../_components/header";
import "react-image-gallery/styles/css/image-gallery.css";
import "./PhotoGallery.css";

export const PhotoGallery = () => {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);

  // Access query string parameters
  const galleryId = queryString.get("id");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [galleryImages, setGalleryImages] = useState<ReactImageGalleryItem[]>(
    []
  );

  useEffect(() => {
    const fetchImageFiles = async () => {
      try {
        const response = await axios.get(`/images/gallery/${galleryId}`);
        let data = response.data;
        constructGalleryImages(data);
      } catch (error) {
        console.error("Error fetching image files:", error);
      }
    };

    fetchImageFiles();
  }, []);

  const handleScreenChange = (event: boolean) => {
    if (event) {
      let tempImages = galleryImages;
      tempImages.forEach((tempImage) => {
        delete tempImage.originalHeight;
        delete tempImage.originalWidth;
      });
      setGalleryImages(tempImages);
    } else {
      let tempImages = galleryImages;
      tempImages.map((tempImage) => {
        tempImage.originalHeight = 400;
        return tempImages;
      });

      setGalleryImages(tempImages);
    }
  };

  const constructGalleryImages = (inputImages: string[]) => {
    let reactImageGalleryItems: ReactImageGalleryItem[] = [];
    inputImages.length > 0 &&
      inputImages.forEach((imageName) => {
        let reactImageGalleryItem = {
          original: `/images/gallery/${galleryId}/${imageName}`,
          thumbnail: `/images/gallery/${galleryId}/${imageName}`,
          originalHeight: 400,
          thumbnailHeight: 45,
        };

        reactImageGalleryItems.push(reactImageGalleryItem);
      });

    setGalleryImages(reactImageGalleryItems);
  };

  return (
    <>
      <Header />
      <div className="">
        <div className="smgm-photogallery-title">
          <h2>Photo Gallery</h2>
        </div>
        <ImageGallery
          items={galleryImages}
          lazyLoad={true}
          showPlayButton={false}
          showIndex={true}
          onScreenChange={handleScreenChange}
        />
      </div>
    </>
  );
};
