import { useState, useEffect } from "react";
import { Header } from "../../../_components/header";
import { NotificationModal } from "../../../_components/modals/notification-modal";
import Table from "react-bootstrap/Table";
import axios from "axios";
import "./ShareCapital.css";

export const ShareCapital = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstParagraphText, setFirstParagraphText] = useState<string>("");
  const [secondParagraphText, setSecondParagraphText] = useState<string>("");
  const [thirdParagraphText, setThirdParagraphText] = useState<string>("");
  const [signatureText, setSignatureText] = useState<string>("");
  const [imagePath, setImagePath] = useState<string>("");
  const [noticePath, setNoticePath] = useState<string>("");

  useEffect(() => {
    setShowModal(true);
    let url = `${process.env.REACT_APP_API_URL}/website-page-settings/sharecapital`;

    setIsLoading(true);

    try {
      axios.get(url).then((response: any) => {
        if (response.data.length > 0) {
          setFirstParagraphText(response.data[0].FirstParagraphText);
          setSecondParagraphText(response.data[0].SecondParagraphText);
          setThirdParagraphText(response.data[0].ThirdParagraphText);
          setSignatureText(response.data[0].SignatureText);
          setImagePath(response.data[0].PageImagePath);
          setNoticePath(response.data[0].NoticeImagePath);
        }
      });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Header />
      <div className="">
        <div className="smgm-sharecapital-title">
          <h2>Share Capital</h2>
        </div>
        <div className="container smgm-sharecapital-content">
          {/* <div className="row">
            <div className="col-lg-2 col-md-2">
              {imagePath && (
                <div className="smgm-sharecapital-img-container">
                  <img
                    src={
                      process.env.REACT_APP_STATIC_URL +
                      "/website/pages/" +
                      imagePath
                    }
                    alt="share-capital"
                  />
                </div>
              )}
              <div className="smgm-sharecapital-img-description"></div>
            </div>
            <div className="col-lg-10 col-md-10">
              <p>{firstParagraphText}</p>
              <p>{secondParagraphText}</p>
              <p>{thirdParagraphText}</p>
              <p>{signatureText}</p>
            </div>
          </div> */}
          <div className="smgm-sharecapital-content-table">
            <Table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>F/Y Year</th>
                  <th>Capital Invested</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2078/079</td>
                  <td>25,000.000</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>2077/078</td>
                  <td>35,000.000</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>2076/077</td>
                  <td>45,000.000</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>2075/076</td>
                  <td>55,000.000</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>2074/075</td>
                  <td>65,000.000</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>2073/074</td>
                  <td>65,000.000</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>2072/073</td>
                  <td>65,000.000</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>2071/072</td>
                  <td>65,000.000</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>2070/071</td>
                  <td>65,000.000</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>2069/070</td>
                  <td>65,000.000</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>2068/069</td>
                  <td>65,000.000</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {showModal && noticePath && (
        <NotificationModal
          imagePath={
            process.env.REACT_APP_STATIC_URL + "/website/pages/" + noticePath
          }
          onClose={handleClose}
        />
      )}
    </>
  );
};
