import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Home } from "./pages/home";
import { AboutUs } from "./pages/about-us";
import { VisionAndMission } from "./pages/about-us/vision-and-mission";
import { BoardOfDirectors } from "./pages/about-us/board-of-directors";
import { PhotoAlbum } from "./pages/about-us/photo-album";
import { PhotoGallery } from "./pages/about-us/photo-gallery";
import { AnnualReport } from "./pages/investor/annual-report";
import { Dividend } from "./pages/investor/dividend";
import { ShareCapital } from "./pages/investor/share-capital";
import { DailySaving } from "./pages/deposit/daily-saving";
import { MonthlySaving } from "./pages/deposit/monthly-saving";
import { SuperSaving } from "./pages/deposit/super-saving";
import { ChildrenSaving } from "./pages/deposit/children-saving";
import { SrCitizenSaving } from "./pages/deposit/sr-citizen-saving";
import { ShareBridhiSaving } from "./pages/deposit/share-bridhi-saving";
import { KhutrukeSaving } from "./pages/deposit/khutruke-saving";
import { AvadhikSaving } from "./pages/deposit/avadhik-saving";
import { FixedDeposits } from "./pages/deposit/fixed-deposits";
import { PersonalLoan } from "./pages/loan/personal-loan";
import { BusinessLoan } from "./pages/loan/business-loan";
import { HouseLoan } from "./pages/loan/house-loan";
import { HirePurchaseLoan } from "./pages/loan/hire-purchase-loan";
import { EducationLoan } from "./pages/loan/education-loan";
import { TermLoan } from "./pages/loan/term-loan";
import { FdReceiptLoan } from "./pages/loan/fd-receipt-loan";
import { MobileBanking } from "./pages/service/mobile-banking";
import { HomeCashCollection } from "./pages/service/home-cash-collection";
import { TransactionHour } from "./pages/service/transaction-hour";
import { Contact } from "./pages/contact";
import { CompanySettings } from "./pages/settings/company-settings";
import { PageSettings } from "./pages/settings/page-settings";
import { Login } from "./pages/login";

export default function AppRouter() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />

          <Route path="/about-us" component={AboutUs} />
          <Route path="/vision-and-mission" component={VisionAndMission} />
          <Route path="/board-of-directors" component={BoardOfDirectors} />
          <Route path="/photo-album" component={PhotoAlbum} />
          <Route path="/photo-gallery" component={PhotoGallery} />

          <Route path="/annual-report" component={AnnualReport} />
          <Route path="/dividend" component={Dividend} />
          <Route path="/share-capital" component={ShareCapital} />

          <Route path="/daily-saving" component={DailySaving} />
          <Route path="/monthly-saving" component={MonthlySaving} />
          <Route path="/super-saving" component={SuperSaving} />
          <Route path="/children-saving" component={ChildrenSaving} />
          <Route path="/sr-citizen-saving" component={SrCitizenSaving} />
          <Route path="/share-bridhi-saving" component={ShareBridhiSaving} />
          <Route path="/khutruke-saving" component={KhutrukeSaving} />
          <Route path="/avadhik-saving" component={AvadhikSaving} />
          <Route path="/fixed-deposits" component={FixedDeposits} />

          <Route path="/personal-loan" component={PersonalLoan} />
          <Route path="/business-loan" component={BusinessLoan} />
          <Route path="/house-loan" component={HouseLoan} />
          <Route path="/hire-purchase-loan" component={HirePurchaseLoan} />
          <Route path="/education-loan" component={EducationLoan} />
          <Route path="/term-loan" component={TermLoan} />
          <Route path="/fd-receipt-loan" component={FdReceiptLoan} />

          <Route path="/mobile-banking" component={MobileBanking} />
          <Route path="/home-cash-collection" component={HomeCashCollection} />
          <Route path="/transaction-hour" component={TransactionHour} />

          <Route path="/contact" component={Contact} />

          <Route path="/company-settings" component={CompanySettings} />
          <Route path="/page-settings" component={PageSettings} />

          <Route path="/login" component={Login} />

          <Route exact path="" component={Home} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
