import React, { useState } from "react";
import { CompanySettingsModal } from "../models/CompanySettings.model";

export interface ICompanySettingsContext {
  contextCompanySettings: CompanySettingsModal;
  updateContextCompanySettings: (companySettings: CompanySettingsModal) => void;
}

export const CompanySettingsContext = React.createContext<
  ICompanySettingsContext
>({
  contextCompanySettings: new CompanySettingsModal(),
  updateContextCompanySettings: (companySettings: CompanySettingsModal) =>
    console.log(companySettings),
});

export const CompanySettingsContextProvider = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [contextCompanySettings, setContextCompanySettings] = useState<
    CompanySettingsModal
  >(new CompanySettingsModal());

  const updateContextPageSettings = (companySettings: CompanySettingsModal) => {
    setContextCompanySettings(companySettings);
  };

  return (
    <CompanySettingsContext.Provider
      value={{
        contextCompanySettings: contextCompanySettings,
        updateContextCompanySettings: updateContextPageSettings,
      }}
    >
      {props.children}
    </CompanySettingsContext.Provider>
  );
};
