import { Header } from "../../_components/header";
import "./Contact.css";

export const Contact = () => {
  return (
    <>
      <Header />
      <div className="">
        <div className="smgm-contact-title">
          <h2>Contact Details</h2>
        </div>
        <div className="h-100 d-flex justify-content-center">
          <div className="container smgm-contact-content">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-2 col-4 smgm-contact-label">
                Phone Number:
              </div>
              <div className="col-lg-2 col-8 smgm-contact-value">
                01-4951920
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-4 smgm-contact-label">Mobile:</div>
              <div className="col-lg-2 col-8 smgm-contact-value">
                +977 9841862943
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-4 smgm-contact-label">
                Whats App:
              </div>
              <div className="col-lg-2 col-8 smgm-contact-value">
                +977 9841862943
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-4 smgm-contact-label">Viber:</div>
              <div className="col-lg-2 col-8 smgm-contact-value">
                +977 9841862943
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-4 smgm-contact-label">Email:</div>
              <div className="col-lg-2 col-8 smgm-contact-value">
                samyukta.manab@gmail.com
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-4 smgm-contact-label">Website:</div>
              <div className="col-lg-2 col-8 smgm-contact-value">
                <a href="https://samyuktamanab.com.np">
                  https://samyuktamanab.com.np
                </a>
              </div>
            </div>
            <div className="row row-cols-lg-6 d-flex justify-content-center">
              <div className="col-lg-2 col-4 smgm-contact-label">Facebook:</div>
              <div className="col-lg-2 col-8 smgm-contact-value">
                {" "}
                <a href="https://facebook.com/samyuktamanab">
                  https://facebook.com/samyuktamanab
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
