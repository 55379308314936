import { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  CompanySettingsContext,
  ICompanySettingsContext,
} from "../../context/CompanySettingsContext";
import {
  isStorageKeyAvailable,
  getStorageKey,
  removeStorageKey,
} from "../../services/utility.service";
import { CompanySettingsModal } from "../../models/CompanySettings.model";
import "./Header.css";
import { NavDropdown } from "../nav-dropdown/NavDropdown";

export interface IHeaderProps {
  isLoggedIn?: boolean;
}

export const Header = (props: IHeaderProps) => {
  const history = useHistory();
  const {
    contextCompanySettings: contextCompanySettings,
    updateContextCompanySettings: updateContextCompanySettings,
  } = useContext<ICompanySettingsContext>(CompanySettingsContext);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    props.isLoggedIn || false
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toggleRef = useRef<any>(null);

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/website-settings`;

    try {
      setIsLoading(true);
      axios.get(url).then((response) => {
        const data = response?.data;

        if (data && data.length > 0) {
          let companySettings: CompanySettingsModal = {
            companyName: data[0].CompanyName,
            companyTitle: data[0].CompanyTitle,
            companyAddress: data[0].CompanyAddress,
            companyContactNo: data[0].CompanyContactNo,
            companyLogoPath: data[0].CompanyLogoPath,
            companyBanner1Path: data[0].CompanyBanner1Path,
            companyBanner2Path: data[0].CompanyBanner2Path,
            companyBanner3Path: data[0].CompanyBanner3Path,
            companyBanner4Path: data[0].CompanyBanner4Path,
            updatedBy: data[0].UpdatedBy,
            updatedDate: data[0].UpdatedDate,
          };

          updateContextCompanySettings(companySettings);
        }
      });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    try {
      setIsLoading(true);
      const smgmToken = isStorageKeyAvailable();
      if (smgmToken) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        toggleRef.current &&
        !toggleRef.current.contains(event.target) &&
        !event.target.pathname
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuClick = (event: any, page: string) => {
    event.preventDefault();
    if (page.toLowerCase() === "logout") {
      removeStorageKey();
      setIsLoggedIn(false);
      history?.push("/");
    } else {
      history?.push("/" + page.toLowerCase());
    }
  };

  return (
    <>
      <div className="smgm-header-wrapper">
        <header className="smgm-header">
          <div className="smgm-header-content" style={{ height: "85px" }}>
            <div style={{ display: "flex" }}>
              <div
                className="smgm-company-logo-wrapper"
                onClick={(event) => handleMenuClick(event, "home")}
              >
                {contextCompanySettings.companyLogoPath ? (
                  <img
                    className="smgm-company-logo"
                    src={
                      process.env.REACT_APP_API_STATIC_URL +
                      "/website/company/" +
                      contextCompanySettings.companyLogoPath
                    }
                  />
                ) : (
                  <img
                    className="smgm-company-logo"
                    src={
                      process.env.REACT_APP_STATIC_URL +
                      "/images/dummy/no-image-available.png"
                    }
                    alt={contextCompanySettings?.companyName || ``}
                  />
                )}
              </div>
              <div className="smgm-header-text">
                <div className="smgm-company-name">
                  <label>{contextCompanySettings?.companyName || ``}</label>
                </div>
                <div className="smgm-company-title">
                  <label>{contextCompanySettings?.companyTitle || ``}</label>
                </div>
              </div>
            </div>
            <div className="smgm-company-name-small">
              <label>{contextCompanySettings?.companyName || ``}</label>
            </div>
            <div className="smgm-navdropdown" style={{ paddingTop: "25px" }}>
              <ul className="links">
                <li className="menu-container">
                  <a
                    className="menu"
                    href="#"
                    onClick={(event) => handleMenuClick(event, "home")}
                  >
                    Home
                  </a>
                </li>
                <li className="menu-container">
                  <a className="menu" href="#">
                    About Us
                  </a>
                  <div className="submenu-container">
                    <a
                      href="#"
                      onClick={(event) => handleMenuClick(event, "about-us")}
                    >
                      About Us
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "vision-and-mission")
                      }
                    >
                      Vision and Mission
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "board-of-directors")
                      }
                    >
                      Board of Directors
                    </a>
                    <a
                      href="#"
                      onClick={(event) => handleMenuClick(event, "photo-album")}
                    >
                      Photo Album
                    </a>
                  </div>
                </li>
                <li className="menu-container">
                  <a className="menu" href="#">
                    Investor
                  </a>
                  <div className="submenu-container">
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "annual-report")
                      }
                    >
                      Annual Report
                    </a>
                    <a
                      href="#"
                      onClick={(event) => handleMenuClick(event, "dividend")}
                    >
                      Dividend
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "share-capital")
                      }
                    >
                      Share Capital
                    </a>
                  </div>
                </li>
                <li className="menu-container">
                  <a className="menu" href="#">
                    Deposit
                  </a>
                  <div className="submenu-container">
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "daily-saving")
                      }
                    >
                      Daily Saving
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "monthly-saving")
                      }
                    >
                      Monthly Saving
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "super-saving")
                      }
                    >
                      Super Saving
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "children-saving")
                      }
                    >
                      Children Saving
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "sr-citizen-saving")
                      }
                    >
                      Sr. Citizen Saving
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "share-bridhi-saving")
                      }
                    >
                      Share Bridhi Saving
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "khutruke-saving")
                      }
                    >
                      Khutruke Saving
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "avadhik-saving")
                      }
                    >
                      Avadhik Saving
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "fixed-deposits")
                      }
                    >
                      Fixed Deposits
                    </a>
                  </div>
                </li>
                <li className="menu-container">
                  <a className="menu" href="#">
                    Loan
                  </a>
                  <div className="submenu-container">
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "personal-loan")
                      }
                    >
                      Personal Loan
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "business-loan")
                      }
                    >
                      Business Loan
                    </a>
                    <a
                      href="#"
                      onClick={(event) => handleMenuClick(event, "house-loan")}
                    >
                      House Loan
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "hire-purchase-loan")
                      }
                    >
                      Hire Purchase Loan
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "education-loan")
                      }
                    >
                      Education Loan
                    </a>
                    <a
                      href="#"
                      onClick={(event) => handleMenuClick(event, "term-loan")}
                    >
                      Term Loan
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "fd-receipt-loan")
                      }
                    >
                      FD Receipt Loan
                    </a>
                  </div>
                </li>
                <li className="menu-container">
                  <a className="menu" href="#">
                    Service
                  </a>
                  <div className="submenu-container">
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "mobile-banking")
                      }
                    >
                      Mobile Banking
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "home-cash-collection")
                      }
                    >
                      Home Cash Collection
                    </a>
                    <a
                      href="#"
                      onClick={(event) =>
                        handleMenuClick(event, "transaction-hour")
                      }
                    >
                      Transaction Hour
                    </a>
                  </div>
                </li>
                {isLoggedIn && (
                  <li className="menu-container">
                    <a className="menu" href="#">
                      Settings
                    </a>
                    <div className="submenu-container">
                      <a
                        href="#"
                        onClick={(event) =>
                          handleMenuClick(event, "company-settings")
                        }
                      >
                        Company Settings
                      </a>
                      <a
                        href="#"
                        onClick={(event) =>
                          handleMenuClick(event, "page-settings")
                        }
                      >
                        Page Settings
                      </a>
                    </div>
                  </li>
                )}
                <li className="menu-container">
                  <a className="menu" href="#">
                    Contact Us
                  </a>
                  <div className="submenu-container">
                    <a
                      href="#"
                      onClick={(event) => handleMenuClick(event, "contact")}
                    >
                      Contact
                    </a>
                  </div>
                </li>
                <li className="menu-container">
                  <a className="menu" href={process.env.REACT_APP_GROCERY_URL}>
                    Grocery
                  </a>
                </li>
                <li>
                  <a
                    className="menu"
                    href="#"
                    onClick={(event) =>
                      handleMenuClick(
                        event,
                        `${isLoggedIn ? "Logout" : "Login"}`
                      )
                    }
                  >
                    {isLoggedIn ? "Logout" : "Login"}
                  </a>
                </li>
              </ul>
            </div>
            <div className="smgm-navdropdown-small">
              <NavDropdown />
            </div>
          </div>
        </header>

        <header className="smgm-sub-header">
          <div className="smgm-navbar" style={{ height: "40px" }}>
            <div className="smgm-navbar-text">
              <div>
                <span
                  className="smgm-navbar-address"
                  style={{ paddingRight: "25px" }}
                >
                  Central Office :{" "}
                  {contextCompanySettings?.companyAddress || ``}
                </span>
                <span
                  className="smgm-navbar-address-small"
                  style={{ paddingRight: "25px" }}
                >
                  {contextCompanySettings?.companyAddress || ``}
                </span>
                <label className="smgm-company-contact-text">Call</label>{" "}
                <i className="fa fa-sm fa-phone smgm-company-contact-icon"></i>{" "}
                <label>{contextCompanySettings?.companyContactNo || ``}</label>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};
