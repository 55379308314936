import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Header } from "../../../_components/header";
import { FileUpload } from "../../../_components/file-upload";
import { getStorageKey } from "../../../services/utility.service";
import { CompanySettingsModal } from "../../../models/CompanySettings.model";
import { renameFile } from "../../../services/utility.service";
import { MessageModal } from "../../../_components/modals/message-modal";
import "./CompanySettings.css";

export const CompanySettings = () => {
  const history = useHistory();
  const apiUrl = process.env.REACT_APP_API_URL || "";

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isCleared, setIsCleared] = useState<boolean>(false);

  const [companyName, setCompanyName] = useState<string>("");
  const [companyTitle, setCompanyTitle] = useState<string>("");
  const [companyAddress, setCompanyAddress] = useState<string>("");
  const [companyContactNo, setCompanyContactNo] = useState<string>("");
  const [companyLogoPath, setCompanyLogoPath] = useState<string>("");
  const [companyBanner1Path, setCompanyBanner1Path] = useState<string>("");
  const [companyBanner2Path, setCompanyBanner2Path] = useState<string>("");
  const [companyBanner3Path, setCompanyBanner3Path] = useState<string>("");
  const [companyBanner4Path, setCompanyBanner4Path] = useState<string>("");

  const [companyLogoImage, setCompanyLogoImage] = useState<any>({});
  const [companyBanner1Image, setCompanyBanner1Image] = useState<any>({});
  const [companyBanner2Image, setCompanyBanner2Image] = useState<any>({});
  const [companyBanner3Image, setCompanyBanner3Image] = useState<any>({});
  const [companyBanner4Image, setCompanyBanner4Image] = useState<any>({});

  const clearSettings = () => {
    setCompanyName("");
    setCompanyTitle("");
    setCompanyAddress("");
    setCompanyContactNo("");
    setCompanyLogoPath("");
    setCompanyBanner1Path("");
    setCompanyBanner2Path("");
    setCompanyBanner3Path("");
    setCompanyBanner4Path("");

    setIsCleared(true);
  };

  const getSettings = () => {
    let config = {};
    const smgmToken = getStorageKey();
    if (smgmToken) {
      config = {
        headers: {
          Authorization: smgmToken,
        },
      };
    } else {
      history.push("/login");
    }
    try {
      axios.get(`${apiUrl}/website-settings`, config).then((response: any) => {
        if (response.data.length > 0) {
          setCompanyName(response.data[0].CompanyName);
          setCompanyTitle(response.data[0].CompanyTitle);
          setCompanyAddress(response.data[0].CompanyAddress);
          setCompanyContactNo(response.data[0].CompanyContactNo);
          setCompanyLogoPath(response.data[0].CompanyLogoPath);
          setCompanyBanner1Path(response.data[0].CompanyBanner1Path);
          setCompanyBanner2Path(response.data[0].CompanyBanner2Path);
          setCompanyBanner3Path(response.data[0].CompanyBanner3Path);
          setCompanyBanner4Path(response.data[0].CompanyBanner4Path);
        }
      });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  };

  const updateSettings = () => {
    let config = {};
    const smgmToken = getStorageKey();
    if (smgmToken) {
      config = {
        headers: {
          Authorization: smgmToken,
        },
      };
    } else {
      history.push("/login");
    }

    let newCompanyLogoImageName = "";
    let newCompanyBanner1ImageName = "";
    let newCompanyBanner2ImageName = "";
    let newCompanyBanner3ImageName = "";
    let newCompanyBanner4ImageName = "";

    const formData = new FormData();

    if (companyLogoImage && companyLogoImage?.name) {
      let originalCompanyLogoImageName = companyLogoImage.name;
      newCompanyLogoImageName =
        "company-logo." + originalCompanyLogoImageName.split(".").pop();
      const newCompanyLogoImage = renameFile(
        companyLogoImage,
        newCompanyLogoImageName
      );
      formData.append("images", newCompanyLogoImage);
    } else if (companyLogoPath) {
      newCompanyLogoImageName = companyLogoPath;
      formData.append("images", companyLogoPath);
    }

    if (companyBanner1Image && companyBanner1Image?.name) {
      let originalCompanyBanner1ImageName = companyBanner1Image.name;
      newCompanyBanner1ImageName =
        "company-banner1." + originalCompanyBanner1ImageName.split(".").pop();
      const newCompanyBanner1Image = renameFile(
        companyBanner1Image,
        newCompanyBanner1ImageName
      );
      formData.append("images", newCompanyBanner1Image);
    } else if (companyBanner1Path) {
      newCompanyBanner1ImageName = companyBanner1Path;
      formData.append("images", companyBanner1Path);
    }

    if (companyBanner2Image && companyBanner2Image?.name) {
      let originalCompanyBanner2ImageName = companyBanner2Image.name;
      newCompanyBanner2ImageName =
        "company-banner2." + originalCompanyBanner2ImageName.split(".").pop();
      const newCompanyBanner2Image = renameFile(
        companyBanner2Image,
        newCompanyBanner2ImageName
      );
      formData.append("images", newCompanyBanner2Image);
    } else if (companyBanner2Path) {
      newCompanyBanner2ImageName = companyBanner2Path;
      formData.append("images", companyBanner2Path);
    }

    if (companyBanner3Image && companyBanner3Image?.name) {
      let originalCompanyBanner3ImageName = companyBanner3Image.name;
      newCompanyBanner3ImageName =
        "company-banner3." + originalCompanyBanner3ImageName.split(".").pop();
      const newCompanyBanner3Image = renameFile(
        companyBanner3Image,
        newCompanyBanner3ImageName
      );
      formData.append("images", newCompanyBanner3Image);
    } else if (companyBanner3Path) {
      newCompanyBanner3ImageName = companyBanner3Path;
      formData.append("images", companyBanner3Path);
    }

    if (companyBanner4Image && companyBanner4Image?.name) {
      let originalCompanyBanner4ImageName = companyBanner4Image.name;
      newCompanyBanner4ImageName =
        "company-banner4." + originalCompanyBanner4ImageName.split(".").pop();
      const newCompanyBanner4Image = renameFile(
        companyBanner4Image,
        newCompanyBanner4ImageName
      );
      formData.append("images", newCompanyBanner4Image);
    } else if (companyBanner4Path) {
      newCompanyBanner4ImageName = companyBanner4Path;
      formData.append("images", companyBanner4Path);
    }

    const companySettings = new CompanySettingsModal();
    companySettings.companyName = companyName;
    companySettings.companyTitle = companyTitle;
    companySettings.companyAddress = companyAddress;
    companySettings.companyContactNo = companyContactNo;
    companySettings.companyLogoPath = newCompanyLogoImageName;
    companySettings.companyBanner1Path = newCompanyBanner1ImageName;
    companySettings.companyBanner2Path = newCompanyBanner2ImageName;
    companySettings.companyBanner3Path = newCompanyBanner3ImageName;
    companySettings.companyBanner4Path = newCompanyBanner4ImageName;
    companySettings.updatedBy = "admin";

    try {
      axios
        .put(`${apiUrl}/website-settings`, companySettings, config)
        .then((response: any) => {
          if (response) {
            try {
              axios
                .post(
                  `${apiUrl}/website-settings/upload-images`,
                  formData,
                  config
                )
                .then((response) => {
                  setIsDisabled(true);
                  setMessage("Settings updated successfully.");
                  setShowMessageModal(true);
                })
                .catch((error) => console.log(error));
            } catch (error) {
              // Handle error
            }
          }
        });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSettings = () => {
    let config = {};
    const smgmToken = getStorageKey();
    if (smgmToken) {
      config = {
        headers: {
          Authorization: smgmToken,
        },
      };
    } else {
      history.push("/login");
    }

    try {
      axios
        .delete(`${apiUrl}/website-settings`, config)
        .then((response: any) => {
          if (response) {
            clearSettings();
            setMessage("Settings deleted successfully.");
            setShowMessageModal(true);
          }
        });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (action: string) => {
    if (action == "show") {
      getSettings();
    } else if (action == "edit") {
      setIsDisabled(false);
    } else if (action == "update") {
      updateSettings();
    } else if (action == "clear") {
      clearSettings();
    } else if (action == "delete") {
      deleteSettings();
    }
  };

  const handleCompanyLogoImageChange = (companyLogoImage: any) => {
    setCompanyLogoImage(companyLogoImage);
    if (!companyLogoImage) {
      setCompanyLogoPath("");
    }
  };

  const handleCompanyBanner1ImageChange = (companyBanner1Image: any) => {
    setCompanyBanner1Image(companyBanner1Image);
    if (!companyBanner1Image) {
      setCompanyBanner1Path("");
    }
  };

  const handleCompanyBanner2ImageChange = (companyBanner2Image: any) => {
    setCompanyBanner2Image(companyBanner2Image);
    if (!companyBanner2Image) {
      setCompanyBanner2Path("");
    }
  };

  const handleCompanyBanner3ImageChange = (companyBanner3Image: any) => {
    setCompanyBanner3Image(companyBanner3Image);
    if (!companyBanner3Image) {
      setCompanyBanner3Path("");
    }
  };

  const handleCompanyBanner4ImageChange = (companyBanner4Image: any) => {
    setCompanyBanner4Image(companyBanner4Image);
    if (!companyBanner4Image) {
      setCompanyBanner4Path("");
    }
  };

  const handleMessageModalClose = () => {
    setShowMessageModal(false);
  };

  return (
    <>
      <Header />
      <div className="smgm-container">
        <div className="smgm-companysettings-title">
          <h2>Company Settings</h2>
        </div>
        <div className="container smgm-companysettings-content">
          <div className="row">
            <div
              className="col-lg-2 col-md-2"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <div style={{ display: "flex" }}>
                <Button
                  className="smgm-companysettings-action-buttons"
                  variant="primary"
                  onClick={() => handleClick("show")}
                >
                  Show
                </Button>
                <Button
                  className="smgm-companysettings-action-buttons"
                  variant="primary"
                  onClick={() => handleClick("edit")}
                >
                  Edit
                </Button>
                <Button
                  className="smgm-companysettings-action-buttons"
                  variant="primary"
                  onClick={() => handleClick("update")}
                >
                  Update
                </Button>
                <Button
                  className="smgm-companysettings-action-buttons"
                  variant="primary"
                  onClick={() => handleClick("clear")}
                >
                  Clear
                </Button>
                <Button
                  className="smgm-companysettings-action-buttons"
                  variant="danger"
                  onClick={() => handleClick("delete")}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
          <div className="row smgm-companysettings-form-row">
            <div className="col-lg-9 col-md-9">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="smgm-companysettings-form-label">
                  Company Name
                </Form.Label>
                <Form.Control
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Company Name"
                  disabled={isDisabled}
                />
                <Form.Label className="smgm-companysettings-form-label">
                  Company Title
                </Form.Label>
                <Form.Control
                  type="text"
                  value={companyTitle}
                  onChange={(e) => setCompanyTitle(e.target.value)}
                  placeholder="Company Title"
                  disabled={isDisabled}
                />
                <Form.Label className="smgm-companysettings-form-label">
                  Address.
                </Form.Label>
                <Form.Control
                  type="text"
                  value={companyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  placeholder="Address"
                  disabled={isDisabled}
                />
                <Form.Label className="smgm-companysettings-form-label">
                  Contact No.
                </Form.Label>
                <Form.Control
                  type="text"
                  value={companyContactNo}
                  onChange={(e) => setCompanyContactNo(e.target.value)}
                  placeholder="Contact No."
                  disabled={isDisabled}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Label className="smgm-companysettings-form-label">
                Company Logo
              </Form.Label>
              <div style={{ display: "flex", height: "160px", width: "180px" }}>
                <FileUpload
                  file={
                    companyLogoPath
                      ? process.env.REACT_APP_API_STATIC_URL +
                        "/website/company/" +
                        companyLogoPath
                      : null
                  }
                  disabled={isDisabled}
                  clear={isCleared}
                  onChange={handleCompanyLogoImageChange}
                />
              </div>
            </div>
          </div>
          <div className="row smgm-companysettings-form-row">
            <div className="col-lg-10 col-md-10">
              <Form.Group className="mb-3" controlId="secondParagraph">
                <Form.Label className="smgm-companysettings-form-label">
                  Company Banner
                </Form.Label>
                <div style={{ display: "flex", width: "800px" }}>
                  <FileUpload
                    file={
                      companyBanner1Path
                        ? process.env.REACT_APP_API_STATIC_URL +
                          "/website/company/" +
                          companyBanner1Path
                        : null
                    }
                    disabled={isDisabled}
                    clear={isCleared}
                    style={{
                      height: "160px",
                      width: "600px",
                      paddingRight: "10px",
                    }}
                    onChange={handleCompanyBanner1ImageChange}
                  />
                  <FileUpload
                    file={
                      companyBanner2Path
                        ? process.env.REACT_APP_API_STATIC_URL +
                          "/website/company/" +
                          companyBanner2Path
                        : null
                    }
                    disabled={isDisabled}
                    clear={isCleared}
                    style={{
                      height: "160px",
                      width: "600px",
                      paddingLeft: "10px",
                    }}
                    onChange={handleCompanyBanner2ImageChange}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="row smgm-companysettings-form-row">
            <div className="col-lg-10 col-md-10">
              <Form.Group className="mb-3" controlId="secondParagraph">
                <Form.Label className="smgm-companysettings-form-label">
                  Company Banner
                </Form.Label>
                <div style={{ display: "flex", width: "600px" }}>
                  <FileUpload
                    file={
                      companyBanner3Path
                        ? process.env.REACT_APP_API_STATIC_URL +
                          "/website/company/" +
                          companyBanner3Path
                        : null
                    }
                    disabled={isDisabled}
                    clear={isCleared}
                    style={{
                      height: "160px",
                      width: "600px",
                      paddingRight: "10px",
                    }}
                    onChange={handleCompanyBanner3ImageChange}
                  />
                  <FileUpload
                    file={
                      companyBanner4Path
                        ? process.env.REACT_APP_API_STATIC_URL +
                          "/website/company/" +
                          companyBanner4Path
                        : null
                    }
                    disabled={isDisabled}
                    clear={isCleared}
                    style={{
                      height: "160px",
                      width: "600px",
                      paddingLeft: "10px",
                    }}
                    onChange={handleCompanyBanner4ImageChange}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
      {showMessageModal && (
        <MessageModal message={message} onClose={handleMessageModalClose} />
      )}
    </>
  );
};
