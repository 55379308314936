import { useRef, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import "./FileUpload.css";

export interface IFileUploadProps {
  file: string | null;
  disabled: boolean;
  clear: boolean;
  style?: any;
  onChange: (filePath: string) => void;
}

export const FileUpload = (props: IFileUploadProps) => {
  const inputFileRef = useRef<any>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(props.file);

  useEffect(() => {
    if (props.file) {
      setSelectedFile(props.file);
    }
  }, [props.file]);

  useEffect(() => {
    if (props.clear) {
      setSelectedFile(null);
    }
  }, [props.clear]);

  const handleClick = (event: any) => {
    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleChange = (event: any) => {
    const fileName = URL.createObjectURL(event.target.files[0]);
    if (fileName) {
      setSelectedFile(fileName);
      props.onChange(event.target.files[0]);
    }
  };

  const handleDelete = () => {
    setSelectedFile(null);
    props.onChange("");
  };

  return (
    <div>
      <div
        style={props.style ? props.style : { height: "100%", width: "100%" }}
      >
        <img
          style={{ height: "100%", width: "100%" }}
          src={
            selectedFile
              ? selectedFile
              : process.env.REACT_APP_STATIC_URL +
                "/images/dummy/no-image-available.png"
          }
          alt="Image"
        />
      </div>
      <div style={{ paddingTop: "5px" }}>
        <span>
          <button
            className="button-upload"
            onClick={handleClick}
            disabled={props.disabled}
          >
            Upload File
          </button>
          <input
            type="file"
            accept="image/*"
            onChange={handleChange}
            ref={inputFileRef}
            style={{ display: "none" }}
          />
        </span>
        <span style={{ padding: "2px" }}>
          <Button
            variant="danger"
            onClick={handleDelete}
            disabled={props.disabled}
          >
            Delete
          </Button>
        </span>
      </div>
    </div>
  );
};
