import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./MessageModal.css";
import { Button } from "react-bootstrap";

export interface IMessageModalProps {
  message: string;
  onClose: () => void;
}

export const MessageModal = (props: IMessageModalProps) => {
  const [message, setMessage] = useState<string>(props.message);

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  const handleHide = () => {
    props.onClose();
  };

  return (
    <>
      <Modal show={true} onHide={handleHide} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <h5>{message}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
