import { useState, useEffect } from "react";
import { Header } from "../../../_components/header";
import { NotificationModal } from "../../../_components/modals/notification-modal";
import axios from "axios";
import "./BoardOfDirectors.css";

export const BoardOfDirectors = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstParagraphText, setFirstParagraphText] = useState<string>("");
  const [secondParagraphText, setSecondParagraphText] = useState<string>("");
  const [thirdParagraphText, setThirdParagraphText] = useState<string>("");
  const [signatureText, setSignatureText] = useState<string>("");
  const [imagePath, setImagePath] = useState<string>("");
  const [noticePath, setNoticePath] = useState<string>("");

  useEffect(() => {
    setShowModal(true);
    let url = `${process.env.REACT_APP_API_URL}/website-page-settings/boardofdirectors`;

    setIsLoading(true);

    try {
      axios.get(url).then((response: any) => {
        if (response.data.length > 0) {
          setFirstParagraphText(response.data[0].FirstParagraphText);
          setSecondParagraphText(response.data[0].SecondParagraphText);
          setThirdParagraphText(response.data[0].ThirdParagraphText);
          setSignatureText(response.data[0].SignatureText);
          setImagePath(response.data[0].PageImagePath);
          setNoticePath(response.data[0].NoticeImagePath);
        }
      });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Header />
      <div className="">
        <div className="smgm-boardofdirectors-title">
          <h2>Board of Directors</h2>
        </div>
        <div
          className="container smgm-boardofdirectors-content"
          style={{ marginBottom: "20px" }}
        >
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {/* <p>{firstParagraphText}</p>
              <p>{secondParagraphText}</p>
              <p>{thirdParagraphText}</p>
              <p>{signatureText}</p> */}
              <div className="container">
                <div className="row" style={{ padding: "10px" }}>
                  <div className="col-md-12 col-sm-12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "/images/pages/page-image-boardofdirectors-chairman.jpg"
                        }
                        alt=""
                        style={{ height: "200px", width: "190px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Bhai Raja Manandhar
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Chairman
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row" style={{ padding: "10px" }}>
                  <div className="col-md-4 col-sm-12 p-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "/images/pages/page-image-boardofdirectors-vicechairman.jpg"
                        }
                        alt=""
                        style={{ height: "200px", width: "190px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Jesus Krishna Nyachhyon
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Vice Chairman
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 p-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "/images/pages/page-image-boardofdirectors-treasurer.jpg"
                        }
                        alt=""
                        style={{ height: "200px", width: "190px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Nilendra Kuikel
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Treasurer
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 p-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "/images/pages/page-image-boardofdirectors-secretary.jpg"
                        }
                        alt=""
                        style={{ height: "200px", width: "190px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Nabaraj Bhatta
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Secretary/Loan Officer
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row" style={{ padding: "10px" }}>
                  <div className="col-md-4 col-sm-12 p-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "/images/pages/page-image-boardofdirectors-member1.jpg"
                        }
                        alt=""
                        style={{ height: "200px", width: "190px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Bharat Ram Rijal
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Member
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 p-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "/images/pages/page-image-boardofdirectors-member2.jpg"
                        }
                        alt=""
                        style={{ height: "200px", width: "190px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Rajendra Hari Mool
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Member
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 p-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "/images/pages/page-image-boardofdirectors-member3.jpg"
                        }
                        alt=""
                        style={{ height: "200px", width: "190px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Sashi Nakarmi
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Member
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && noticePath && (
        <NotificationModal
          imagePath={
            process.env.REACT_APP_STATIC_URL + "/website/pages/" + noticePath
          }
          onClose={handleClose}
        />
      )}
    </>
  );
};
