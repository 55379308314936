import { useState, useEffect, useContext } from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import {
  CompanySettingsContext,
  ICompanySettingsContext,
} from "../../context/CompanySettingsContext";
import { Header } from "../../_components/header";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Home.css";
import axios from "axios";
import { NotificationModal } from "../../_components/modals/notification-modal";

export const Home = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstParagraphText, setFirstParagraphText] = useState<string>("");
  const [secondParagraphText, setSecondParagraphText] = useState<string>("");
  const [thirdParagraphText, setThirdParagraphText] = useState<string>("");
  const [signatureText, setSignatureText] = useState<string>("");
  const [imagePath, setImagePath] = useState<string>("");
  const [noticePath, setNoticePath] = useState<string>("");

  const {
    contextCompanySettings: contextCompanySettings,
    updateContextCompanySettings: updateContextCompanySettings,
  } = useContext<ICompanySettingsContext>(CompanySettingsContext);

  const [galleryImages, setGalleryImages] = useState<ReactImageGalleryItem[]>(
    []
  );

  useEffect(() => {
    setShowModal(true);
    let url = `${process.env.REACT_APP_API_URL}/website-page-settings/home`;

    setIsLoading(true);

    try {
      axios.get(url).then((response: any) => {
        if (response.data.length > 0) {
          setFirstParagraphText(response.data[0].FirstParagraphText);
          setSecondParagraphText(response.data[0].SecondParagraphText);
          setThirdParagraphText(response.data[0].ThirdParagraphText);
          setSignatureText(response.data[0].SignatureText);
          setImagePath(response.data[0].PageImagePath);
          setNoticePath(response.data[0].NoticeImagePath);
        }
      });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setShowModal(true);
    let images = [];
    if (contextCompanySettings.companyBanner1Path) {
      images.push({
        original:
          process.env.REACT_APP_API_STATIC_URL +
          "/website/company/" +
          contextCompanySettings.companyBanner1Path,
      });
    }
    if (contextCompanySettings.companyBanner2Path) {
      images.push({
        original:
          process.env.REACT_APP_API_STATIC_URL +
          "/website/company/" +
          contextCompanySettings.companyBanner2Path,
      });
    }
    if (contextCompanySettings.companyBanner3Path) {
      images.push({
        original:
          process.env.REACT_APP_API_STATIC_URL +
          "/website/company/" +
          contextCompanySettings.companyBanner3Path,
      });
    }
    if (contextCompanySettings.companyBanner4Path) {
      images.push({
        original:
          process.env.REACT_APP_API_STATIC_URL +
          "/website/company/" +
          contextCompanySettings.companyBanner4Path,
      });
    }

    setGalleryImages(images);
  }, [contextCompanySettings]);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Header />
      <section>
        <div className="p-1" style={{ width: "100%", height: "auto" }}>
          <ImageGallery
            items={galleryImages}
            lazyLoad={true}
            autoPlay={true}
            showNav={false}
            showPlayButton={false}
            showFullscreenButton={false}
            showBullets={true}
            isRTL={true}
          />
        </div>
      </section>
      {showModal && noticePath && (
        <NotificationModal
          imagePath={
            process.env.REACT_APP_API_STATIC_URL +
            "/website/pages/" +
            noticePath
          }
          onClose={handleClose}
        />
      )}
    </>
  );
};
