import { useState, useEffect } from "react";
import { Header } from "../../../_components/header";
import { NotificationModal } from "../../../_components/modals/notification-modal";
import Table from "react-bootstrap/Table";
import axios from "axios";
import "./PhotoAlbum.css";

export const PhotoAlbum = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstParagraphText, setFirstParagraphText] = useState<string>("");
  const [secondParagraphText, setSecondParagraphText] = useState<string>("");
  const [thirdParagraphText, setThirdParagraphText] = useState<string>("");
  const [signatureText, setSignatureText] = useState<string>("");
  const [imagePath, setImagePath] = useState<string>("");
  const [noticePath, setNoticePath] = useState<string>("");

  useEffect(() => {
    setShowModal(true);
    let url = `${process.env.REACT_APP_API_URL}/website-page-settings/photoalbum`;

    setIsLoading(true);

    try {
      axios.get(url).then((response: any) => {
        if (response.data.length > 0) {
          setFirstParagraphText(response.data[0].FirstParagraphText);
          setSecondParagraphText(response.data[0].SecondParagraphText);
          setThirdParagraphText(response.data[0].ThirdParagraphText);
          setSignatureText(response.data[0].SignatureText);
          setImagePath(response.data[0].PageImagePath);
          setNoticePath(response.data[0].NoticeImagePath);
        }
      });
    } catch (error) {
      // Handle Error
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Header />
      <div className="">
        <div className="smgm-photoalbum-title">
          <h2>Photo Album</h2>
        </div>
        <div className="container smgm-photoalbum-content">
          {/* <div className="row">
            <div className="col-lg-2 col-md-2">
              {imagePath && (
                <div className="smgm-photoalbum-img-container">
                  <img
                    src={
                      process.env.REACT_APP_STATIC_URL +
                      "/website/pages/" +
                      imagePath
                    }
                    alt="photo-album"
                  />
                </div>
              )}
              <div className="smgm-photoalbum-img-description"></div>
            </div>
            <div className="col-lg-10 col-md-10">
              <p>{firstParagraphText}</p>
              <p>{secondParagraphText}</p>
              <p>{thirdParagraphText}</p>
              <p>{signatureText}</p>
            </div>
          </div> */}
          <div className="smgm-photoalbum-content-table">
            <Table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Photo Album</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>11th AGM 2078-079</td>
                  <td>
                    <a href="photo-gallery?id=1">View Photos</a>
                  </td>
                </tr>
                <tr>
                  <td>10th AGM 2077-078</td>
                  <td>
                    <a href="photo-gallery?id=1">View Photos</a>
                  </td>
                </tr>
                <tr>
                  <td>9th AGM 2076-077</td>
                  <td>
                    <a href="photo-gallery?id=1">View Photos</a>
                  </td>
                </tr>
                <tr>
                  <td>8th AGM 2075-076</td>
                  <td>
                    <a href="photo-gallery?id=1">View Photos</a>
                  </td>
                </tr>
                <tr>
                  <td>7th AGM 2074-075</td>
                  <td>
                    <a href="photo-gallery?id=1">View Photos</a>
                  </td>
                </tr>
                <tr>
                  <td>6th AGM 2073-074</td>
                  <td>
                    <a href="photot-gallery?id=1">View Photos</a>
                  </td>
                </tr>
                <tr>
                  <td>5th AGM 2072-073</td>
                  <td>
                    <a href="photot-gallery?id=1">View Photos</a>
                  </td>
                </tr>
                <tr>
                  <td>4th AGM 2071-072</td>
                  <td>
                    <a href="photot-gallery?id=1">View Photos</a>
                  </td>
                </tr>
                <tr>
                  <td>3rd AGM 2070-071</td>
                  <td>
                    <a href="photot-gallery?id=1">View Photos</a>
                  </td>
                </tr>
                <tr>
                  <td>2nd AGM 2069-070</td>
                  <td>
                    <a href="photot-gallery?id=1">View Photos</a>
                  </td>
                </tr>
                <tr>
                  <td>1st AGM 2068-069</td>
                  <td>
                    <a href="photot-gallery?id=1">View Photos</a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {showModal && noticePath && (
        <NotificationModal
          imagePath={
            process.env.REACT_APP_STATIC_URL + "/website/pages/" + noticePath
          }
          onClose={handleClose}
        />
      )}
    </>
  );
};
